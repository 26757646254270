a, Link{
  text-decoration: none;
  color: inherit;
}
.company-grid{
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  gap: 2.5rem;
  margin-bottom: 8rem;
}

.company_box {
  position: relative;
  width: 300px;
  height: 280px;
  border: 1px solid var(--color-green);
  border-radius: 10px;

  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;  
  box-shadow: rgba(255, 255, 255, 0.2) 0px 1px 10px 0px;
  transition: all ease 0.4s;
  font-family: 'Poppins';
}

.overlay {
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
}

.company_box:hover .overlay {
  height: 100%;
}
.company_box:hover{
  transform: translate(0, -4px);
}

.company_box_text {
  color: white;
  font-size: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  width: 80%;
  line-height: 25px;
}

@media screen and (max-width: 1260px) {
  .company-grid{
    gap: 1rem;
  }  
}
@media screen and (max-width: 960px) {  
  .company_box{
    width: 15rem;
    height: 16rem;
  }
  #titleTrack{
    margin-top: 1rem;
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 770px) {
  .company-grid{
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 560px) {
  .company-grid{
    grid-template-columns: auto;
  }
  .overlay{
    display: none;
  }
  .cards-top-head h1{
    font-size: 10vw !important;
  }
}
