#team__parent{
    margin-top: -12%;
    
}
.team-container{
    width: 90%;
    margin: auto;
}
.icon-quote:after{
    background-image: url(/src/assets/quoteIcon.png);

    content: "";
    display: block;
    width: 184px;
    height: 131px;
    position: absolute;
    left: 75px;
    margin-top: -10rem;
    transform: translateY(-25%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: -1;
}


.mySwiper
{
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 2rem;
    box-shadow: 0px 8x 32px 0 #CBC3E3;
}

.client__avatar{
    /* width: 4rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    align-items: left !important; */
    width: 15%;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    /* align-items: center; */
    margin: 0 1em 0 1em;
    /* border: 4px solid var(--color-dark-green); */
}
.client__avatar img{
    height: 100%; width: 100%; object-fit: contain
}
.team{    
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
    height: 30rem;
    /* width: 80%!important; */
}

.client__review{
    /* color: var(--color-light);
    font-weight: 300;
    display: block; */
    width: 85%;
    margin: auto;
    line-height: 25px;
    font-size: 20px;
}

.swiper-pagination-clickable .swiper-pagination-bullet-active{

    background: var(--color-green);    
}
.swiper-button-next,
.swiper-button-prev {
    color: white!important;}


.lin
{
    margin: auto;
    width: 80%;
    margin-top: 50px;
    border-top: 0px solid;
    margin-bottom: 50px;
    
}
.client__container{
    display: flex;
    align-items: center;
}
.team-flex{
    display: flex;
}

@media screen and (max-width: 1024px) {    
    .mySwiper{
        width: 100%;
    }
}

@media screen and (max-width: 1300px) {
    .client__review{
        width: var(--container-width-sm);
        font-size: 30px!important;
        line-height: 35px;
    }
    .team{
        width: 100%!important;
    }
    .team-flex{
        flex-direction: column;
        gap: 2rem;
    }
}

@media screen and (max-width: 700px) {
    .client__review{
        width: var(--container-width-sm);
        font-size: 20px!important;
        line-height: 25px;
    }
    .team{
        width: 100%!important;
    }
    .team-flex{
        flex-direction: column;
        gap: 2rem;
    }
}

.swiper-slide {    
  background: rgba(255, 255, 255, 0.057);  
  
}

@media screen and (min-width: 1024px){
    .mySwiper{
    width: 70%;
}
}
@media (max-width: 600px){
    .team-container{
        margin-bottom: 20%;        
    }
    .icon-quote{
        margin-top: -20%;
    }
}