.gallery {
  font-size: 5rem;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.swiper
  .swiper-fade
  .swiper-initialized
  .swiper-horizontal
  .swiper-pointer-events
  .swiper-watch-progress
  .swiper-backface-hidden
  .mySwiper {
  height: auto;
  width: 100%;
  max-width: 100%;
  max-height: 80vh;
  text-align: center;
}
.gallery-page {
  margin-top: 5rem;
}

.swiper-images {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: auto;
}

.car__head {
  max-height: 80vh !important;
  background-repeat: no-repeat;
  width: auto;
  object-fit: cover;
}
.all__img:hover {
  transform: scale(1.02);
}
.all__img {
  transition: all 0.3s ease;
}
.all__img:focus {
  outline: none;
}

.cards-gallery {
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
  position: relative;
  color: #f0f0f0;
  box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}
.cards-gallery img {
  object-fit: cover;
  opacity: 0.9;
  filter: grayscale(0);
  transition: all 0.3s ease;
}

.cards-gallery p {
  position: absolute;
  opacity: 0;
  max-width: 80%;
  transition: all 0.3s ease;
}
.cards-gallery p {
  inset: auto auto 80px 30px;
}
.cards-gallery-btn {
  inset: auto auto 40px 30px;
  background-color: transparent;
  color: red;
  text-decoration: none;
  position: absolute;
  opacity: 0;
  width: 80%;
  transition: all 0.3s ease;
}

.cards-gallery:hover p {
  opacity: 1;
  transition: all 0.3s ease;
}
.cards-gallery:hover img {
  transition: all 0.3s ease;
  opacity: 1;
  filter: grayscale(100);
}
.cards-gallery-btn:hover {
  opacity: 1;
  transition: all 0.3s ease;
}

.MuiMasonry-root {
  align-content: center !important;
  width: fit-content !important;
}

@media screen and (min-width: 900px) {
  .MuiMasonry-root {
    margin: inherit !important;
  }
}
@media screen and (min-width: 600px) {
  .MuiMasonry-root {
    margin: inherit !important;
  }
}
@media screen and (min-width: 0px) {
  .MuiMasonry-root {
    margin: inherit !important;
  }
}
