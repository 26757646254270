table {
    width: 100%;
    }
    td,th {
      border: 2px solid #777;
      padding: 10px;
    }
    td:third-child {
      width: 30%;
    }
    td:second-child {
      width: 30%;
    }