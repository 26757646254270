.hello{
  margin-left: 6vw;
  margin-right: 6vw;
  font-size: 12rem;
  margin-bottom: 2rem;
}
.hero-container{
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin-top: 10rem;  
}

.enter
{
  background-color: #BCA8D6 !important;
  color: black;
}
.hero-head{
margin-top: 5%;
}

.right{
  text-align: right;
}
.hero-content{
  width: 30%;
  height: auto;
  position: absolute;
  right: 10rem;
  bottom: 0rem;
  font-size: 1.5rem;
}

.hero-content-text{
  margin-bottom: 2rem;
  color: #ffffff80;
  font-family: 'Poppins', sans-serif;
}

@media screen and (max-width: 1350px) {
  .hero-content{
      position: initial;
      width: 60%;
  }
}

@media screen and (max-width: 1024px) {
  .hello {
    font-size: 8rem;
  }
  .hero-content{
      width: 60%;
      position: initial;
      font-size: 1.3rem;
  }
}

@media screen and (max-width: 600px) {
  .hello {
    font-size: 5rem;
  }
  .hero-content{
      width: 80%;
      position: initial;
      font-size: 1.2rem;
  }
}

@media screen and (max-width: 450px){
  .hero-head{
    margin-top: 20%;
  }
}
@media screen and (min-width: 2080px){
  .hero-content{
    right: 15rem;
    bottom: -10rem;
}
}


/* For the animation of the future section */
.update {
  line-height: 160px;
  margin-left: -10px;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
  background: url(/src/assets/future-03.jpg);
  -webkit-background-clip: text;
  background-position: 0 0;
  animation: back 20s linear infinite;
}
@keyframes back {
  100% {
    background-position: 2000px 0;
  }
}

/* For the Enter now button */
/* .cssbuttons-io-button {
  background: #A370F0;
  color: white;
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1.2em;
  font-size: 17px;
  font-weight: 500;
  border-radius: 0.9em;
  border: none;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 1.6em -0.6em #714da6;
  overflow: hidden;
  position: relative;
  height: 2.8em;
  padding-right: 3.3em;
 }
 
 .cssbuttons-io-button .icon {
  background: white;
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2em;
  width: 2.2em;
  border-radius: 0.7em;
  box-shadow: 0.1em 0.1em 0.6em 0.2em #7b52b9;
  right: 0.3em;
  transition: all 0.3s;
 }
 
 .cssbuttons-io-button:hover .icon {
  width: calc(100% - 0.6em);
 }
 
 .cssbuttons-io-button .icon svg {
  width: 1.1em;
  transition: transform 0.3s;
  color: #7b52b9;
 }
 
 .cssbuttons-io-button:hover .icon svg {
  transform: translateX(0.1em);
 }
 
 .cssbuttons-io-button:active .icon {
  transform: scale(0.95);
 }
  */

  /* For Enter Now rocket theme button */
  button {
    display: flex;
    align-items: center;
    font-family: inherit;
    font-weight: 500;
    font-size: 17px;
    padding: 0.8em 1.3em 0.8em 0.9em;
    color: white;
    background: #7b52b9;
    /* background: linear-gradient(to right, #0f0c29, #302b63, #24243e); */
    /* background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(243,86,78,1) 100%); */
    /* background: linear-gradient(90deg, rgba(238,62,62,1) 0%, rgba(102,73,76,1) 52%, rgba(159,131,226,1) 100%); */

    border: none;
    letter-spacing: 0.05em;
    border-radius: 16px;
  }
  
  button svg {
    margin-right: 3px;
    transform: rotate(30deg);
    transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  }
  
  button span {
    transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  }
  
  button:hover svg {
    transform: translateX(5px) rotate(90deg);
  }
  
  button:hover span {
    transform: translateX(7px);
  }