.left__side__info {
  font-size: 1.8rem;
  line-height: 2.2rem;
}
.left__side__info h3 {
  width: 120%;
}
.right__side__info {
  display: flex;
  gap: 1rem;
  margin-left: auto;
  margin-right: 0%;
}

.explore-info {
  align-items: center;
  position: relative;
  width: min-content;
  transition: all 100ms ease-in;
}
.explore-info-text {
  position: absolute;
  top: 50px;
  left: 35px;
}
.explore-info-img {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 10s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.icon__info {
  font-size: 1.2rem;
  margin-left: 1rem;
}

.happening > .container h1 {
  margin-top: 8rem;
  font-size: 6rem;
  margin-bottom: 7rem;
}

.happens {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 2rem;
}
.left__happens {
  display: flex;
  gap: 1rem;
  font-size: 1.7rem;
}
.right__happens p {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  color: var(--color-light);
  margin-left: -30%;
}

@media screen and (max-width: 600px) {
  .left__side__info {
    text-align: center;
  }
  .right__side__info {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .happens {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
  .left__side__info h3 {
    width: 150%;
  }
}

@media screen and (max-width: 800px) {  
  .happens {
    grid-template-columns: auto;
    gap: 1rem;
  }
  .right__happens p {
    margin-left: 0;
  }
  .happening > .container h1 {
    font-size: 4rem;
  }
  .infoGrid{
    display: grid;
    grid-template-columns: auto auto;
    width: 80%;
    justify-content: center;
    margin-left: 0;
  }  
}
@media screen and (max-width: 500px) {
  .happening > .container h1 {
    font-size: 3rem;
  }
  .infoGrid{
    grid-template-columns: auto;    
  }
  .infoGrid .left__side__info h3{
    
  }
  .infoGrid .right__side__info{
    justify-content: center;
  }
}
