.loader span {
    font-size: 40px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 600;
    animation: blur 3s linear infinite;
    line-height: 20px;
    transition: all 0.5s;
    letter-spacing: 0.2em;
   }
   
   @keyframes blur {
    0%, 90% {
     filter: blur(0);
    }
   
    50% {
     filter: blur(10px);
    }
   }