.banner {
    position: relative;
    .banner-row {
      overflow: hidden;
      display: flex;
      align-items: center;
      @media (max-width: 1440px) {
        &:first-child {
          margin-top: 48px;
        }
      }
      &.center {
        justify-content: center;
        text-align: center;
        .row-letter {
          left: 0;
        }
      }
      &.right {
        justify-content: flex-end;
      }
      .row-title,
      .row-letter {
        font-size: 12rem;
        font-weight: 500;
        position: relative;
        letter-spacing: -0.8rem;
        display: inline-block;
        white-space: nowrap;
        @media (max-width: 700px) {
          font-size: 7rem;
        }
      }
      .row-col {
        width: 50%;
        display: flex;
        align-content: center;
        justify-content: flex-start;
        @media (max-width: 1440px) {
          width: 70%;
        }
        @media (max-width: 1240px) {
          &:last-child {
            display: none;
          }
        }
        @media (max-width: 480px) {
          width: 100%;
          justify-content: flex-start;
        }
        .row-message {
          font-weight: 500;
          font-size: 1.8rem;
          line-height: 2.6rem;
          width: 320px;
        }
      }
      .scroll {
        height: 160px;
        width: 160px;
        border-radius: 100%;
        background: white;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        left: 160px;
        @media (max-width: 1440px) {
          left: 60px;
        }
        @media (max-width: 1240px) {
          display: none;
        }
        span {
          font-size: 18px;
          font-weight: 600;
          margin: 4px 0;
          @media (max-width: 480px) {
            font-size: 10px;
            margin: 2px 0;
          }
        }
      }
    }
  }
  
  .transition-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 800px;
      display: flex;
    }
    &.final {
      display: block;
      top: -128px;
      position: relative;
      width: 90%;
      margin: 0 auto;
      z-index: -100;
      @media (max-width: 480px) {
        top: -56px;
      }
      img {
        width: 100%;
        max-width: 100%;
      }
    }
  }